import React from 'react';
import { responsiveStyles } from '@audi/audi-ui-react-v2';
import styled from 'styled-components';
import {
  getClassNamesForLinks,
  getIdNameForLink,
  getLinkTarget,
  onCookieModalLinkClick,
} from '../../services/helpers';
import { Link } from '../../types/audi-footer-response.types';

type LinksProps = {
  countryCode?: string;
  links: Link[];
};

const FooterLinksWrapperStyled = styled.div`
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  ${({ theme }): string =>
    responsiveStyles(
      {
        'background-color': { m: 'var(--one-footer-color-black)' },
        height: { m: 'auto' },
        margin: { m: '0' },
        'max-height': { m: 'initial' },
        opacity: { m: '1' },
      },
      theme.breakpoints
    )}
  transition: all 240ms var(${({ theme }): string => theme.easing});

  & > ul {
    padding: calc(var(--one-footer-space-l) - var(--one-footer-space-m) / 2) 0;
    /* stylelint-disable */
    ${({ theme }): string =>
      responsiveStyles(
        {
          padding: { m: '0' },
        },
        theme.breakpoints
      )}

    & > li {
      padding: 0 var(--one-footer-side-spacing);
      ${({ theme }): string =>
        responsiveStyles(
          {
            padding: { m: '0 0 var(--one-footer-space-s)' },
          },
          theme.breakpoints
        )}
      /* stylelint-enable */
      & > a {
        display: block;
        padding: calc(var(--one-footer-space-m) / 2) 0;
        width: 100%;
        ${({ theme }): string =>
          responsiveStyles(
            {
              display: { m: 'inline' },
              padding: { m: '0' },
              width: { m: 'auto' },
            },
            theme.breakpoints
          )}
      }
    }

    & > li:last-of-type {
      ${({ theme }): string =>
        responsiveStyles(
          {
            padding: { m: '0' },
          },
          theme.breakpoints
        )}
    }
  }
`;

const Links: React.FC<LinksProps> = ({ countryCode, links }) => {
  return (
    <FooterLinksWrapperStyled>
      <ul>
        {links.map((link) => {
          const randomNumber = Math.floor(Math.random() * 1000) + 1;
          return (
            <li
              /* nemo specific cookie modal link click */
              {...(!!link.Type && link.Type === 'cookie-settings-layer' && countryCode !== 'US'
                ? { onClick: onCookieModalLinkClick }
                : {})}
              data-is-overview-link={link.IsOverview}
              key={`${randomNumber}${link.Target}${link.Text}${link.Url}`}
            >
              <a
                className={getClassNamesForLinks(link, countryCode)}
                href={link.Url}
                target={getLinkTarget(link)}
                {...(getIdNameForLink(link) ? { id: getIdNameForLink(link) } : {})}
              >
                {link.Text}
              </a>
            </li>
          );
        })}
      </ul>
    </FooterLinksWrapperStyled>
  );
};

export default Links;
